<template>
  <div class="executive-education">
    <b-row class="rowas" style="background: #aaaaaa;">
      <div class="topImg m-auto">
        <img loading="lazy"  alt="education" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/education.jpg" style="width: 100%"/>
      </div>
    </b-row>
    <div class="container">
        <b-row class="rowas">
          <div class="col-12 col-sm-9 col-md-8 noPadding plusAncho">
            <p style="font-size: 34px; letter-spacing: 0px;"><img loading="lazy" alt="lupa" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">Educación <span>ejecutiva</span></p>
          </div>
        </b-row>
      </div>
      <div class="col-xs-12 noPadding plusAncho back-gran">
        <div class="container">
          <div class="col-md-12 openPrograms">
            <b-row class="container-flex" style="width: 100%;">
              <div class="col iconCol">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon1.png" class="img-responsive">
                  <p class="title_desPrograms">Entrenamos a miles de ejecutivos al rededor del mundo en inteligencia digital</p>
              </div>
              <div class="col iconCol">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon2.png" class="img-responsive">
                  <p class="title_desPrograms">+ de 40 delegaciones del Club y alianzas estratégicas  de ámbito global</p>
              </div>
              <div class="col iconCol">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon3.png" class="img-responsive">
                  <p class="title_desPrograms">In Company Open Programs / Digital Thinking and Learning Internetworked</p>
              </div>
              <div class="col iconCol">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon4.png" class="img-responsive">
                  <p class="title_desPrograms">Potente Red Social colaborativa de Networking para oportunidades de negocio</p>
              </div>
            </b-row>

            <b-row>
              <div class="text-left mb-3" style="width: 100%">
                <h3 id="collapseTitle">Sesión magistral online</h3>
              </div>
              <ul style="width: 100%">
                <li v-b-toggle.collapse-1 class="list-item">
                  <div class="row text-left">
                    <div class="col-10 text">
                      ¿Quieres seducir a tus clientes? Descubre cómo optimizar
                      tu estrategia para mejorar los resultados
                    </div>
                    <div class="col-2">
                      <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-more.png" />
                    </div>
                  </div>
                  <div>
                    <b-collapse id="collapse-1">
                      <div class="row text-left">
                        <div class="col">
                          <div class="row">
                            <div class="col-3">
                              <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/collapse-1.png" />
                            </div>
                            <div class="col-9 two-texts">
                              Aforo limitado <br>
                              <span>Matrículate gratis</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col-3">
                              <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/collapse-2.png" />
                            </div>
                            <div class="col-9 two-texts">
                              19 Nov 2019 <br>
                              <span>Call</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col-3">
                              <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/collapse-3.png" />
                            </div>
                            <div class="col-9 two-texts">
                              Online <br>
                              <span>Webinar</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <a href="#" class="more-info-button">
                            <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-info.png" />
                            More information
                          </a>
                        </div>
                        <div class="col">
                          <a href="#" class="more-info-button read-more-button">
                            <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-info.png" />
                            Read more
                          </a>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </li>
                <li v-b-toggle.collapse-2 class="list-item">
                  <div class="row text-left">
                    <div class="col-10 text">
                      ¿Deseas captar Clientes online y no lo consigues? Participa en el evento
                      dónde presentamos casos reales y te enseñamos cómo hacerlo
                    </div>
                    <div class="col-2">
                      <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-more.png" />
                    </div>
                  </div>
                  <div>
                    <b-collapse id="collapse-2">
                      <div class="row text-left">
                        <div class="col">
                          <div class="row">
                            <div class="col-3">
                              <img loading="lazy" alt="post" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/collapse-1.png" />
                            </div>
                            <div class="col-9 two-texts">
                              aoro limitado <br>
                              <span>Matrículate gratis</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col-3">
                              <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/collapse-2.png" />
                            </div>
                            <div class="col-9 two-texts">
                              28 Ene 2020 <br>
                              <span>Call</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col-3">
                              <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/collapse-3.png" />
                            </div>
                            <div class="col-9 two-texts">
                              Online <br>
                              <span>Webinar</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <a href="#" class="more-info-button">
                            <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-info.png" />
                            More information
                          </a>
                        </div>
                        <div class="col">
                          <a href="#" class="more-info-button read-more-button">
                            <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-info.png" />
                            Read more
                          </a>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </li>
              </ul>
            </b-row>
          </div>
        </div>
      </div>
      <b-row class="clearfix" style="height: 50px;"></b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'education',
  metaInfo() {
    return {
      title: this.$t('message.executive_edu'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('message.executive_edu') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('message.executive_edu') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style lang="scss">
.rowas {
  width: 100vw;
  margin: 0;
}

.container-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.list-item {
  border-bottom: 1px solid #aaa;
  padding-bottom: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

#collapseTitle {
  text-transform: uppercase;
  border-bottom: 1px solid #009090;
}

.list-item {
  .text {
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

.two-texts {
  font-size: 0.9rem;
  line-height: 1rem;
  margin: auto;

  span {
    color: #009090;
    font-size: 0.8rem;
  }
}

.more-info-button {
  background: #009090;
  padding: 7px 15px;
  font-size: 0.8rem;
  color: white !important;
}

.read-more-button {
  background: #007070;
}

.container {
  width: unset !important;
}

.title {
  font-weight: 300;
  font-size: 20pt;
  color: #232323;
  font-family: 'open sans condensed', Arial, sans-serif;
  text-align: left;
  margin-left: 25px;
}

</style>